import React from 'react';
import Layout from '@shared/components/layout/layout';
import { BLANK_TEMPLATE } from '@shared/utils/constants';

const UnsupportedBrowser = () => {
  return (
    <Layout headerMenu={[]} template={BLANK_TEMPLATE} footerMenu={[]}>
      <section className="background-grid-black background-threads careers-threads">
        <div className="container pv120-90-60">
          <div className="row body-space">
            <div className="col-12 align-middle">
              <p className="h3-style">
                Unsupported Browser! 
              </p>
              <p>Please visit CIA.gov using a supported browser.</p>
              <p>Download the latest version of your browser of choice from the providers' websites. Some popular browsers are listed below for reference. <b>Note:</b> These third-party websites are not controlled by the CIA or subject to its Privacy Policy.</p>
              <p>
                <ul>
                  <li><a href="https://www.google.com/chrome/" class="h-6-reverse"><u>Google Chrome</u></a></li>
                  <li><a href="https://www.mozilla.org/en-US/firefox/new/" class="h-6-reverse"><u>Mozilla FireFox</u></a></li>
                  <li><a href="https://support.apple.com/downloads/safari/" class="h-6-reverse"><u>Apple Safari</u></a></li>
                  <li><a href="https://www.microsoft.com/en-us/edge/" class="h-6-reverse"><u>Microsoft Edge</u></a></li>
                </ul>
              </p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};
export default UnsupportedBrowser;
